


























import { defineComponent, onMounted } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import CompanySearchBoxComponent from '@/components/Management/molecules/CompanySearchBox.vue';
import CompanyTableHeaderComponent from '@/components/Management/molecules/CompanyTableHeader.vue';
import { useCompanyDelete } from '@/composables/store/Management/useCompanyDelete';
import { useCompanyDeleteProgressCircular } from '@/composables/store/Management/useCompanyDeleteProgressCircular';
import CompanyDeleteTableComponent from '@/components/Management/organisms/CompanyDeleteTable.vue';
import { useCompanyListProgressCircular } from '@/composables/store/Management/useCompanyListProgressCircular';
import CompanyDeleteAlertComponent from '@/components/Management/molecules/CompanyDeleteAlert.vue';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        CompanySearchBox: CompanySearchBoxComponent,
        CompanyTableHeader: CompanyTableHeaderComponent,
        CompanyDeleteTable: CompanyDeleteTableComponent,
        CompanyDeleteAlert: CompanyDeleteAlertComponent,
    },
    setup() {
        const { deletableCompanyList, fetchList } = useCompanyDelete();
        const { isCompanyFetchProgressing, isCompanyDeleteProgressing } = useCompanyDeleteProgressCircular();
        const { isCompanyListProgressing } = useCompanyListProgressCircular();
        const { error, deleteCompany, deleteList } = useCompanyDelete();

        const isNotLoadingAndExistData = (): boolean => {
            return !!deletableCompanyList.value.length && !isCompanyFetchProgressing.value;
        };

        const execute = async (): Promise<void> => {
            await deleteCompany();
            await fetchList();
        };

        const deletingClass = (): string => (isCompanyDeleteProgressing.value ? 'deleting' : '');

        onMounted(() => {
            fetchList();
        });

        return {
            deletableCompanyList,
            isCompanyFetchProgressing,
            isCompanyDeleteProgressing,
            isCompanyListProgressing,
            error,
            deleteCompany,
            execute,
            deleteList,
            isNotLoadingAndExistData,
            deletingClass,
        };
    },
});
