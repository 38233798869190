


























import { defineComponent } from '@vue/composition-api';
import { useCompanyDelete } from '@/composables/store/Management/useCompanyDelete';

export default defineComponent({
    setup() {
        const { deleteList, deletableCompanyList } = useCompanyDelete();

        return {
            deletableCompanyList,
            deleteList,
        };
    },
});
